import { SaveOutlined } from "@ant-design/icons";
import { Button, Form, Input, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import authApi from "../../../Auth/services/auth.api";
import { logoutAction, selectUser, updateUserAction } from "../../../Auth/state/auth.slice";
import { AppAuth } from "../../../Auth/types";
import ModalConfirm from "../../../common/components/Modals/modal-confirm.component";
import { useAppDispatch, useAppSelector } from "../../../common/hooks";
import { ROUTES } from "../../../common/router";
import { showSuccessNotification } from "../../../common/services/notificationService";
import { openModalAction } from "../../../common/state/modal.slice";

const UserProfileFormInfo = () => {
  const user = useAppSelector(selectUser);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleFinish = async (data: AppAuth) => {
    try {
      const updatedUser = await authApi.updateMe(data); // Assure-toi que cette méthode retourne l'utilisateur mis à jour
      dispatch(updateUserAction(updatedUser));  // Mets à jour l'utilisateur dans le store Redux
      showSuccessNotification("Vos informations ont été mises à jour avec succès.");
    } catch (error) {
      console.error(error);
    }
  };


  const deleteMyAccount = async () => {
    try {
      await authApi.deleteMe();
      dispatch(logoutAction());
      showSuccessNotification("Votre compte a été supprimé avec succès.");
    } catch (error) {
      console.log(error);
    }
  };


  const handleDelete = () => {
    dispatch(
      openModalAction({
        component: (
          <ModalConfirm
            formCb={async (confirm) => {
              if (confirm === true) {
                await deleteMyAccount();
              }
            }}
            confirmIsDanger
            // suppression du compte donc envoi de facture
            body="En supprimant votre compte, vous ne pourrez plus accéder à votre espace personnel.
            Vos factures seront envoyées à votre adresse email."
          />
        ),
        options: { title: "Êtes vous sûr de vouloir supprimer votre compte ?" },
      })
    );
  };




  return (
    <>
      <Form<AppAuth>
        layout="vertical"
        initialValues={user}
        onFinish={handleFinish}
      >
        <div className="flex justify-between">
          <h6 className="text-pz-blue text-sm mt-10 mb-6 font-bold uppercase">
            Informations personnelles
          </h6>
          <Tooltip title="Enregistrer">
            <Button
              type="primary"
              htmlType="submit"
              icon={<SaveOutlined />}
              style={{
                width: "3rem",
                height: "3rem",
                marginRight: "1.5rem",
              }}
            />
          </Tooltip>
        </div>

        <div className="grid grid-cols-2 gap-x-4">
          <Form.Item
            name="firstName"
            label="Prénom"
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="lastName"
            label="Nom"
          >
            <Input />
          </Form.Item>
        </div>

        <Form.Item name="mobile" label="Téléphone">
          <Input />
        </Form.Item>
        <hr className="mt-6 border-b-1" />
        <h6 className="text-pz-blue text-sm mt-3 mb-6 font-bold uppercase">
          Changer mon adresse email
        </h6>
        <Form.Item name="email" label="Email">
          <Input />
        </Form.Item>

        <hr className="mt-6 border-b-1" />
        <h6 className="text-pz-blue text-sm mt-3 mb-6 font-bold uppercase">
          Supprimer le compte
        </h6>
        <div className="flex flex-wrap">
          <div className="flex w-full mt-3 justify-center space-x-4">
            <Button
              onClick={() => handleDelete()}
              className="btn btn-primary">Supprimer mon compte</Button>
          </div>
        </div>
      </Form>
    </>
  );
};

export default UserProfileFormInfo;
