import { Alert, Spin } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../../../common/router";
import authApi from "../../services/auth.api";

const VerifyEmail = () => {
  const { t } = useTranslation();
  const [status, setStatus] = useState({ loading: true, success: false, message: "" });
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get("token");

    if (token) {
      authApi.verifyEmail(token)
        .then(response => {
          setStatus({ loading: false, success: true, message: t("UI.VERIFY_EMAIL_PAGE.SUCCESS") });
          setTimeout(() => {
            navigate(ROUTES.AUTH.LOGIN);
          }, 3000); // Redirection après 3 secondes
        })
        .catch(error => {
          setStatus({ loading: false, success: false, message: error.response?.data?.message || t("UI.VERIFY_EMAIL_PAGE.ERROR") });
        });
    } else {
      setStatus({ loading: false, success: false, message: t("UI.VERIFY_EMAIL_PAGE.INVALID_TOKEN") });
    }
  }, [location.search, t]);

  return (
    <div className="verify-email-container">
      {status.loading ? (
        <Spin size="large" />
      ) : (
        <Alert
          message={status.success ? t("UI.VERIFY_EMAIL_PAGE.SUCCESS_TITLE") : t("UI.VERIFY_EMAIL_PAGE.ERROR_TITLE")}
          description={status.message}
          type={status.success ? "success" : "error"}
          showIcon
        />
      )}
    </div>
  );
};

export default VerifyEmail;
