// rootReducer.ts
import { combineReducers } from 'redux';
import dateSlice from '../../Appointment/Calendar/state/date.slice';
import { DATE_SERVICE } from '../../Appointment/Calendar/state/types';
import authSlice from '../../Auth/state/auth.slice';
import { AUTH_SERVICE } from '../../Auth/state/types';
import serviceSlice from '../../Services/state/service.slice';
import { SERVICES_SERVICE } from '../../Services/state/types';
import modalSlice from '../state/modal.slice';
import { MODAL_SERVICE } from '../state/types';
import appointmentSlice from '../../Appointment/state/appointment.slice';
import { APPOINTMENT_SERVICE } from '../../Appointment/state/types';

export default function createRootReducer(): typeof rootReducer {
  const rootReducer = combineReducers({
    [MODAL_SERVICE]: modalSlice,
    [AUTH_SERVICE]: authSlice,
    [APPOINTMENT_SERVICE]: appointmentSlice,
    [SERVICES_SERVICE]: serviceSlice,
    [DATE_SERVICE]: dateSlice,
    // []
  });

  return (state, action) => rootReducer(state, action);
}