import { Button, Empty, Pagination, Result } from "antd";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import CartItem from "./CartItem";
import CartSummary from "./CartSummary";
import { ICart } from "./types/cartTypes";
import ModalConfirm from "../common/components/Modals/modal-confirm.component";
import { useCart } from "../common/contexts/CartContext";
import { useAppDispatch } from "../common/hooks";
import { ROUTES } from "../common/router";
import { showSuccessNotification } from "../common/services/notificationService";
import { openModalAction } from "../common/state/modal.slice";

const Cart = () => {
  const dispatch = useAppDispatch();
  const { cart, fetchCart, deleteFromCart, deposit, depositDetails, makePayment } = useCart();

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 4;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = cart?.rows.slice(indexOfFirstItem, indexOfLastItem);

  const totalServicePrice =
    cart?.rows.reduce(
      (total: number, item: { serviceId: { price: any } }) =>
        total + Number(item.serviceId.price),
      0
    ) ?? 0;

  const totalAfterDeposit = totalServicePrice - deposit;

  const handleDelete = (cartItem: ICart) => {
    dispatch(
      openModalAction({
        component: (
          <ModalConfirm
            formCb={async (confirm) => {
              if (confirm === true) {
                await deleteFromCart(cartItem._id!);
                showSuccessNotification(
                  "Service supprimé du panier avec succès"
                );

                if (currentItems!.length === 1 && currentPage > 1) {
                  setCurrentPage(currentPage - 1);
                }
              }
            }}
            confirmIsDanger
            body="Êtes vous sur de vouloir supprimer ce service ?"
          />
        ),
        options: { title: "Supprimer ce service" },
      })
    );
  };

  useEffect(() => {
    fetchCart();
  }, []);

  

  return (
    
    <div className="overflow-y-scroll max-h-[800px]">
      {cart?.count === 0 ? (
        <Result
          className="mt-20"
          icon={<Empty description={""} />}
          title="Vous n'avez pas de service dans votre panier."
          extra={
            <Link to={ROUTES.CLIENT.SERVICES}>
              <Button type="primary" className="mt-4">
                Choisir une ou plusieurs prestations
              </Button>
            </Link>
          }
        />
      ) : (
        <section className="py-4 relative">
          <div className="w-full max-w-7xl px-4 md:px-5 lg-6 mx-auto">
            <div className="flex items-start flex-col gap-12 xl:flex-row ">
            <div className="w-full md:max-w-3xl mx-auto overflow-y-auto p-4" style={{ maxHeight: "80vh" }}>

                <div className="grid grid-cols-1 gap-6">
                  <>
                    {currentItems &&
                      currentItems!.map((item: ICart, index:number) => (
                        <CartItem
                          key={index}
                          cart={item}
                          fetchCart={fetchCart}
                          deleteCart={() => handleDelete(item)}
                        />
                      ))}
                  </>
                </div>
              </div>
              <CartSummary
                totalServicePrice={totalServicePrice}
                deposit={deposit}
                totalAfterDeposit={totalAfterDeposit}
                makePayment={() => makePayment(cart)}
                depositDetails={depositDetails}
                currentItems={cart} 
                
              />
            </div>
            <Pagination
              className="mt-4"
              current={currentPage}
              total={cart?.rows.length}
              pageSize={itemsPerPage}
              onChange={(page) => setCurrentPage(page)}
            />
          </div>
        </section>
      )}
    </div>
  );
};

export default Cart;
