import React, { ReactNode } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useSidebar } from "../../../contexts/SidebarContext";
import { Badge, Tooltip } from "antd";
import "../SideNav.css"
import tailwindTheme from "../../../../tailwind-theme";

interface SidebarLinkType {
  link: {
    id: string;
    label: string;
    route?: string;
    icon: ReactNode;
    badge?: number;
    onClick?: () => void;
  };
}

const SideNavLink = ({ link }: SidebarLinkType) => {
  const { expanded } = useSidebar();
  const location = useLocation();

  // Determine if the current link is active
  const isActive = location.pathname === (link.route || "/app");

  const linkClasses = `nav-link ${isActive ? 'active' : ''} ${expanded ? 'expanded' : 'collapsed'}`;

  const linkContent = (
    <NavLink
      to={link.route || "/app"}
      className={linkClasses}
      onClick={link.onClick}
    >
      <span className="icon">{link.icon}</span>
      <span className="link-label">
        {link.label} 
      </span>
      {link.badge && link.id && (
       <Badge
        
       className={`text-xs px-2 py-1 ${isActive && link.id === "cart" ? "text-jb-primary" : "text-white"}`}
       style={{
         color: !expanded ? "rgb(199, 157, 121)" : isActive && link.id === "cart" ? tailwindTheme.colors.jb.primary : "white",
         backgroundColor: !expanded ? "white" : isActive && link.id === "cart" ? "white" : tailwindTheme.colors.jb.primary,
         position: !expanded ? "absolute" : "static",
         top: !expanded ? "-30px" : "auto",
         right: !expanded ? "10px" : "auto",
       }}
       count={link.badge && link.badge > 0 ? (link.badge > 9 ? "9+" : link.badge) : null}
     />
      )}
    </NavLink>
  );
  

  return expanded ? (
    linkContent
  ) : (
    <Tooltip placement="right" title={link.label}>
      {linkContent}
    </Tooltip>
  );
};

export default SideNavLink;
