import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { selectToken } from "../Auth/state/auth.slice";
import { useCart } from "../common/contexts/CartContext";
import { useAppSelector } from "../common/hooks";
import { ApiListResult } from "../common/services/types";
import CardService from "./CardService";
import serviceApi from "./services/service.api";
import { Service } from "./services/types/modelService";
import { Pagination } from "antd";

const Services = () => {
  const token = useAppSelector(selectToken);
  const [services, setServices] = useState<ApiListResult<Service>>();
  const [searchParams, setSearchParams] = useSearchParams();
  const { addToCart } = useCart();
  const search = searchParams.get("search") ?? "";

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(12);

  useEffect(() => {
    serviceApi
      .findServices({}, currentPage, pageSize)
      .then((res) => setServices(res))
      .catch(console.log);
  }, [currentPage, pageSize, search]);


  if (!services) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="w-fit mx-auto grid grid-cols-1 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 justify-items-center justify-center gap-y-3 gap-x-3 ">
        {services.rows.map((service: Service) => (
          <CardService
            key={service._id}
            service={service}
            addToCart={addToCart}
          />
        ))}
      </div>
      {services.count > 12 && ( // Afficher la pagination si plus de 12 services
        <div className="fixed mt-4">
          <Pagination
            current={currentPage}
            pageSize={12}
            total={services.count}
            onChange={(page) => setCurrentPage(page)}
            className="mt-8 text-center"
          />
        </div>
      )}
    </>
  );
};

export default Services;
