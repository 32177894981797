import React, { useState, useEffect } from 'react';
import { Collapse, Empty } from 'antd'; // Utilisation de Collapse d'Ant Design
import invoiceApi from './services/invoice.api';
import { Invoice } from './services/types/modelInvoice';
import { ApiListResult } from '../common/services/types';
import dayjs from 'dayjs';
import InvoicePanel from './InvoicePanel';

const { Panel } = Collapse;

const InvoiceList = () => {
  const [invoices, setInvoices] = useState<ApiListResult<Invoice>>();

  const fetchInvoices = async () => {
    try {
      const res = await invoiceApi.findInvoices({}, 1, 10);
      setInvoices(res);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchInvoices();
  }, []);

  // Fonction pour regrouper les factures par date (createdAt)
  const groupInvoicesByDate = () => {
    if (!invoices) return {};

    return invoices.rows.reduce((groups: { [key: string]: Invoice[] }, invoice: Invoice) => {
      const date = dayjs(invoice.createdAt).format('YYYY-MM-DD');
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(invoice);
      return groups;
    }, {});
  };

  const groupedInvoices = groupInvoicesByDate();

  return (
    <div>
      {Object.keys(groupedInvoices).length === 0 ? ( // Si pas de factures regroupées
        <Empty description="Aucune facture disponible pour le moment" /> // Afficher un message si pas de factures
      ) : (
        <div>
          <h1>Liste des factures</h1>

          <Collapse defaultActiveKey={['0']} expandIconPosition="right" className='space-y-4' >
            {Object.keys(groupedInvoices).map((date, index) => (
              <Panel
                header={`Factures du ${date}`}
                key={index}
                className="border rounded-lg shadow-md p-4 space-y-4"
              >
                <InvoicePanel invoices={groupedInvoices[date]} />
              </Panel>
            ))}
          </Collapse>
        </div>
      )}
    </div>
  );
};

export default InvoiceList;
