import { MoreOutlined } from "@ant-design/icons";

import { Dropdown, Table } from "antd";
import { ColumnsType, TableRowSelection } from "antd/es/table/interface";
import React, { useEffect, useState } from "react";
import UserForm from "./Forms/UserForm";
import { selectToken } from "../../Auth/state/auth.slice";
import Fieldset from "../../common/components/Panels/Fieldset";
import { useAppDispatch, useAppSelector } from "../../common/hooks";
import {
  openDrawerAction,
  openModalAction
} from "../../common/state/modal.slice";
import { User } from "../../User/types/modelUser";
import {
  getUserMenuItems,
  MenuItemContactKeysEnum
} from "./AdminListUserPage.misc";
import adminApi from "../services/admin.api";
import { ApiListResult } from "../../common/services/types";
import UserResume from "./UserResume";
import { useRefresh } from "../../common/contexts/RefreshContext";
import { Appointment } from "../../Appointment/types/modelAppointment";
import ModalConfirm from "../../common/components/Modals/modal-confirm.component";

export const openModalContact = (user: User, dispatch: any, triggerRefresh: any) => {
  dispatch(
    openModalAction({
      component: <UserForm user={user} formCb={() => triggerRefresh()} />,
      options: { title: "Modifier un contact" },
    })
  );
};

export const openModalDeleteUser = (user: User, dispatch: any, fetchUsersAdmin: any) => {
  dispatch(
    openModalAction({
      component: (
        <ModalConfirm
          formCb={async (confirm) => {
            if (confirm === true) {
              await adminApi.deleteUser(user._id!);
              fetchUsersAdmin();
            }
          }}
          confirmIsDanger
          body="Êtes-vous sûr de vouloir supprimer ce contact ?"
        />
      ),
      options: { title: "Supprimer un contact" },
    })
  );
};

export const itemClicked = (user: User, infos: any, openModalContact: Function, openModalDeleteUser: Function) => {
  switch (infos.key) {
    case MenuItemContactKeysEnum.editUser:
      openModalContact(user);
      break;
    case MenuItemContactKeysEnum.deleteUser:
      openModalDeleteUser(user);
      break;
    default:
      break;
  }
};


const AdminListUser: React.FC = () => {
  console.log(itemClicked);
  const [accounts, setUsers] = useState<ApiListResult<User>>();

  const { refreshKey, triggerRefresh } = useRefresh();  // Utiliser le contexte de rafraîchissement
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([])
  const dispatch = useAppDispatch();
  const [refresh, setRefresh] = useState<Date>(new Date());



  const refreshList = () => {
    setRefresh(new Date());
  };

  const fetchUsersAdmin = async () => {
    adminApi
      .findUsersAdmin({}, 1, 10)
      .then((res) => setUsers(res))
      .catch(console.log);
  };


  useEffect(() => {
    fetchUsersAdmin();
  }, [refreshKey]);  // Re-fetch lorsque `refreshKey` change


  const columns: ColumnsType<any> = [
    {
      title: "Nom",
      // dataIndex: "lastName",
      key: "lastName",
      render: (user: any) => (
        <div>
          <UserResume user={user} />
        </div>
      )
    },
    {
      title: "Téléphone",
      dataIndex: "mobile",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: <div className="text-center">Options</div>,
      key: "actions",
      render: (text: string, contact: any) => (
        <div className="text-center">
          <Dropdown menu={{ items: getUserMenuItems(contact, itemClicked as any) }}>
            <MoreOutlined data-testid={`more-options-${contact._id}`} />
          </Dropdown>

        </div>
      ),
    },
  ];





  return (
    <Fieldset title="Vue d'ensemble des rendez-vous : Admin">
      <Table
        rowKey={(record) => record._id} // Utilise _id directement comme clé unique
        columns={columns}
        scroll={{ x: "max-content" }}
        pagination={{
          hideOnSinglePage: true,
          total: accounts?.count,
          pageSize: 5,
        }}
        dataSource={accounts?.rows || []} // Pas besoin de remapper ici
      />

    </Fieldset>
  );
};
export default AdminListUser;
