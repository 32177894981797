import { useDispatch } from "react-redux";
import {
  logoutAction,
  selectUser
} from "../../../Auth/state/auth.slice";
import { SidebarProvider, useSidebar } from "../../contexts/SidebarContext"; // Importez le contexte
import { useAppSelector } from "../../hooks";
import Logo from "../Logo";
import "./SideNav.css";
import SideNavFooter from "./components/SideNavFooter";
import SideNavHeader from "./components/SideNavHeader";
import SideNavSection from "./components/SideNavSection";
import AppointmentSVG from "./icons/sidenavIcons/appointment";
import LogoutSvg from "./icons/sidenavIcons/logout";
import ProfileSvg from "./icons/sidenavIcons/profile";
import ServiceSvg from "./icons/sidenavIcons/service";
import { ROUTES } from "../../router";

export default function SideNavClient() {
  return (
    <SidebarProvider>
      <SideNavContent />
    </SidebarProvider>
  );
}

const SideNavContent = () => {
  const dispatch = useDispatch();
  const user = useAppSelector(selectUser);  
  const { expanded, toggleSidebar } = useSidebar();

  const sidebarLinks = [
    {
      id: "accounts",
      label: "Voir les comptes",
      route: ROUTES.ADMIN.ACCOUNTS,
      icon: <ProfileSvg />,
    },
    {
      id: "appointments",
      label: "Voir les rendez-vous",
      route: ROUTES.ADMIN.DASHBOARD,
      icon: <AppointmentSVG />,
    },
    {
      id: "services",
      label: "Voir les services",
      route: ROUTES.ADMIN.SERVICES,
      icon: <ServiceSvg />,
    },
    {
      id: "logout",
      label: "Déconnexion",
      onClick: () => dispatch(logoutAction()),
      icon: <LogoutSvg />,
    },
  ];
  return (
    <aside id="sidebar">
    <nav
      className={`border-r shadow-sm sidebar  ${expanded ? "expanded" : "collapsed"}`}
    >
        <SideNavHeader expanded={expanded} setExpanded={toggleSidebar} />
        <ul className="flex-1 px-3">
          <div className="logo">
          <Logo expanded={expanded} setExpanded={toggleSidebar} width={200} height={200} logoSrc="/logos/logo.png"  />
          </div>{" "}
          <hr />
          <SideNavSection
            title="Prise de rendez-vous"
            links={sidebarLinks.filter(
              (link) => link.id !== "profile" && link.id !== "logout"
            )}
          />
        </ul>
        <SideNavFooter user={user} dispatch={dispatch} />
      </nav>
    </aside>
  );
};
