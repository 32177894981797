/* ======== CONSTANTES ======== */

/* ======== TYPES ======== */
export type ServiceType = {
  id: string;
  name: string;
  backgroundImage?: string;
  linkForDescription?: string;
  options: [
    {
      optionTitle: string;
      optionType: [
        {
          name: string;
          price: number | string;
          duration:{
            hours: number;
            minutes: number;
            seconds: number;

          }
          brand: string;
        }
      ];
    }
  ];
  createdAt: string;
  updatedAt: string;
};

export type CreateServicePayload = {
  name: string;
  description: string;
  price: number;
  duration: number;
};

export type UpdateServicePayload = {
  id: string;
  name: string;
  description: string;
  price: number;
  duration: number;
};

export type removeServiceFromCartPayload = {
  id: string;
};

export const SERVICES_SERVICE = "serviceService";
// eslint-disable-next-line
export type SERVICES_SERVICE = typeof SERVICES_SERVICE; // Typescript line
