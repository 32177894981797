import { Button, Form, Input } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../../../common/router";
import { loginAction } from "../../state/auth.slice";
import { showErrorNotification } from "../../../common/services/notificationService";
import authApi from "../../services/auth.api";

type FormType = { email: string; password: string };

const ResetPasswordForm = () => {
  const { t } = useTranslation();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  // Extraction du token depuis l'URL
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');

  const resetPasswordSubmit = (e: any) => {
    if (password !== confirmPassword) {
      showErrorNotification('Les mots de passe ne correspondent pas');
      return;
    }

    try {
      const response = authApi.resetPassword(token!, password);
      if (!response) {
        throw new Error('Failed to reset password');
      }
      navigate(ROUTES.AUTH.LOGIN);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Form<FormType> layout="vertical" onFinish={resetPasswordSubmit} className="">
        <Form.Item
          label={t("UI.RESET_PASSWORD_PAGE.FORM.fields.password.label")}
          name="password"
        >
          <Input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          label={t("UI.RESET_PASSWORD_PAGE.FORM.fields.confirmPassword.label")}
        >
          <Input
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </Form.Item>
        <Button htmlType="submit" className="w-full btn">
          {t("UI.RESET_PASSWORD_PAGE.FORM.submit")}
        </Button>
        <div className="flex space-x-3 mt-8 justify-center text-center">
          <Link
            className="text-[#5B5B5B] hover:text-jb-primary font-medium font-yusei"
            to={ROUTES.AUTH.LOGIN}
          >
            Vous avez déjà un compte ?{" "}
            <span className="text-black">Connectez-vous</span>
          </Link>
        </div>
      </Form>
    </>
  );
};

export default ResetPasswordForm;