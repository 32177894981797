import { Button } from "antd";
import { useAppDispatch } from "../../common/hooks";
import { User } from "../../User/types/modelUser";
import { openDrawerAction } from "../../common/state/modal.slice";
import UserForm from "./Forms/UserForm";
import UserFullName from "../../User/components/UserFullName";

type UserResumePropsType = {
  user: User;
  editable?: boolean;
};
const UserResume = ({ user }: UserResumePropsType) => {
  const dispatch = useAppDispatch();

  const openModalContact = (user: User) => {
      dispatch(
        openDrawerAction({
          component: <UserForm user={user} />,
          options: { title: "Modifier un contact" },
        })
      );
  };

  return (
    <div>
      <div>
        <Button type="text" onClick={() => openModalContact(user)}>
          <UserFullName user={user} />
        </Button>
      </div>
    </div>
  );
};

export default UserResume;
