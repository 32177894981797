import React from 'react';
import { FilePdfOutlined } from '@ant-design/icons';
import { Invoice } from './services/types/modelInvoice';
import InvoiceCard from './InvoiceCard';

interface InvoicePanelProps {
  invoices: Invoice[];
}

const InvoicePanel: React.FC<InvoicePanelProps> = ({ invoices }) => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
      {invoices.map((invoice: Invoice) => (
        <InvoiceCard key={invoice.id} invoice={invoice} />
      ))}
    </div>
  );
};

export default InvoicePanel;
