  import React, { createContext, useState, useEffect, ReactNode, useContext } from "react";

  interface SidebarContextProps {
    expanded: boolean;
    toggleSidebar: () => void;
  }

  const SidebarContext = createContext<SidebarContextProps | undefined>(undefined);

  const SidebarProvider = ({ children }: { children: ReactNode }) => {
    const initialExpanded = localStorage.getItem("sidebarExpanded") === "true";
    const [expanded, setExpanded] = useState(initialExpanded);

    useEffect(() => {
      localStorage.setItem("sidebarExpanded", String(expanded));
    }, [expanded]);

    const toggleSidebar = () => {
      setExpanded((prevExpanded) => !prevExpanded);
    };

    return (
      <SidebarContext.Provider value={{ expanded, toggleSidebar }}>
        {children}
      </SidebarContext.Provider>
    );
  };

  const useSidebar = () => {
    const context = useContext(SidebarContext);
    if (!context) {
      throw new Error("useSidebar must be used within a SidebarProvider");
    }
    return context;
  };

  export { SidebarProvider, useSidebar };
