// dateTypes.ts

// Service name
export const DATE_SERVICE = 'dateService';

export interface DateState {
  selectedDate: Date | null;
  selectedHour: string | null;
}

// Action types
export interface SetSelectedDateAction {
  payload: Date;
}

export interface SetSelectedHourAction {
  payload: string;
}

// Combined type
export type DateActionTypes = SetSelectedDateAction | SetSelectedHourAction;