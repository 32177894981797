import { selectUser } from "../../../Auth/state/auth.slice";
import { UserRolesEnum } from "../../../User/types/UserTypes";
import { useAppSelector } from "../../hooks";
import "./SideNav.css";
import SideNavAdmin from "./SideNavAdmin";
import SideNavClient from "./SideNavClient";
import SideNavEsth from "./SideNavEsth";

const SideNav = () => {
  const user = useAppSelector(selectUser);

  return (
    <>
      {user!.role === UserRolesEnum.admin && <SideNavAdmin />}
      {/* si user.role === esth */}
      {user!.role === UserRolesEnum.esthetician && <SideNavEsth />}
      {/* si user.role === client */}
      {user!.role === UserRolesEnum.client && <SideNavClient />}
    </>
  );
};

export default SideNav;
