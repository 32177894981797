import { urlParamsToObject } from './../../common/tools/global-tools';
import { AppointmentStatusEnum, IAppointment } from "../../Appointment/types/appointmentTypes";
import { Appointment } from "../../Appointment/types/modelAppointment";
import { defaultPagination } from "../../common/app-constants";
import ApiService from "../../common/services/apiService";
import { ApiListResult } from "../../common/services/types";
import { Service } from "../../Services/services/types/modelService";
import { User } from "../../User/types/modelUser";
import { IUser } from '../../User/types/UserTypes';
import { IService } from '../../Services/services/types/serviceTypes';

const _PATH_API = "admin/";

class AdminApi extends ApiService<IAppointment> {
  constructor() {
    super(_PATH_API);
  }

  // Appointment



  findAppointmentsAdmin = (
    query = {},
    page = defaultPagination.page,
    pageSize = defaultPagination.pageSize
  ): Promise<{ count: number; rows: Appointment[] }> => {
    return this.custom<ApiListResult<Appointment>>("get", `appointments`, {
      query,
      page,
      pageSize,
    }).then((res) => {
      return {
        count: res.count,
        rows: res.rows.map((r) => new Appointment(r)),
      };
    });
  };

  createAppointment = (data: Partial<IAppointment>) => { 
    return this.custom<Appointment>("post", `appointments`, {}, data, {
      "Content-Type": "application/json",
    });
  };
  
  updateAppointment = (appointmentId: string, data: Partial<IAppointment>) => {
    return this.custom<Appointment>("put", `appointments/update/${appointmentId}`, {}, data);
  };

  updateAppointmentStatus = async (
    appointmentId: string,
    status: AppointmentStatusEnum
  ) => {
    return this.custom<Appointment>("put", `appointments/updateStatus/${appointmentId}`, {}, { status });
  };

  deleteAppointment = (appointmentId: string) => {
    return this.custom<Appointment>("delete", `appointments/${appointmentId}`);
  };



  findUsersAdmin = (
    query = {},
    page = defaultPagination.page,
    pageSize = defaultPagination.pageSize
  ): Promise<{ count: number; rows: User[] }> => {
    return this.custom<ApiListResult<User>>("get", `accounts`, {
      query,
      page,
      pageSize,
    }).then((res) => {
      return {
        count: res.count,
        rows: res.rows.map((r) => new User(r)),
      };
    });
  };

  findServicesAdmin = (
    query = {},
    page = defaultPagination.page,
    pageSize = defaultPagination.pageSize
  ): Promise<{ count: number; rows: Service[] }> => {
    return this.custom<ApiListResult<Service>>("get", `services`, {
      query,
      page,
      pageSize,
    }).then((res) => {
      return {
        count: res.count,
        rows: res.rows.map((r) => new Service(r)),
      };
    });
  };

  createServicesAdmin = (data: IService) => {
    return this.custom<Service>("post", `services`, {}, data, {
      "Content-Type": "application/json",
    });
  };

  updateServicesAdmin = (serviceId: string, data: IService) => {
    return this.custom<Service>("put", `services/${serviceId}`, {}, data);
  };

  uploadImageService = (data: FormData) => {
    return this.custom<Service>("post", `services/upload-image`, {}, data);
  };

  deleteService = (serviceId: string) => {
    return this.custom<Service>("delete", `services/${serviceId}`);
  }
  

 


  
  updateUser = (userId: string, data: Partial<IUser>) => {
    console.log(userId);
    return this.custom<User>("put", `accounts/${userId}`, {}, data);
  };

  deleteUser = (userId: string) => {
    return this.custom<User>("delete", `accounts/${userId}`);
  };
}

const adminApi = new AdminApi();

export default adminApi;
