import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../router';

const NotFound = () => {
    return (
        <>
            <div className='bg-slate-100 flex justify-center items-center h-screen'>
                <div className='container'>
                    <div className='section p-8 text-center'>
                        <h1>Page non trouvée</h1>
                        <h4>Désolé, le contenu demandé n'existe pas.</h4>

                        <div className='my-4'>
                            Vous pouvez retourner consulter la liste de vos événements et démarrer une nouvelle
                            navigation !
                        </div>

                        <div className='flex justify-center'>
                            <img src="/logos/logo.png" className='w-1/4' alt='' />
                        </div>
                        <div className='mt-4'>
                           <Button type='primary' className='mr-2'>
                               <Link to={ROUTES.AUTH.LOGIN}>Retour à l'accueil</Link>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default NotFound;
