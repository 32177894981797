import {
  CalendarOutlined,
  ClockCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { Button, Card } from "antd";
import { useEffect, useState } from "react";
import { Cart } from "./types/modelCart";
import dayjs from "dayjs";
import cartApi from "./services/cart.api";
import { useCart } from "../common/contexts/CartContext";
import { useAppDispatch } from "../common/hooks";
import { openModalAction } from "../common/state/modal.slice";
import { Service } from "../Services/services/types/modelService";
import Calendar from "../Appointment/Calendar/Calendar";

type CartItemProps = {
  cart: Cart;
  deleteCart: () => void;
  fetchCart: () => void;
};

const CartItem = ({ cart, deleteCart }: CartItemProps) => {
  const dispatch = useAppDispatch();
  const {fetchCart} = useCart();

  const [selectedDate, setSelectedDate] = useState<Date | null>();
  const [selectedDateTime, setSelectedDateTime] = useState<Date>();

  useEffect(() => {
    if (selectedDateTime) {
      cartApi
        .updateCartDate(cart._id!, selectedDateTime)
        .then(() => {
          fetchCart();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [selectedDateTime]);

  const openNewSubscriptionFormModal = (service: Service) => {
    dispatch(
      openModalAction({
        component: (
          <Calendar
            onDateSelect={setSelectedDate}
            onDateTimeSelect={setSelectedDateTime}
            service={service}
            cart={cart}
            key={cart._id}
          />
        ),
        options: {
          title: "Quand souhaitez-vous prendre rendez-vous ?",
          width: 800,
          maskClosable: true,
          footer: null,
          style: {
            height: "80vh",
          },
        },
      })
    );
  };

  return (
    <Card
    className="shadow-lg rounded-lg overflow-hidden flex flex-col lg:flex-row mb-4"
    cover={
      <img
        src={cart.serviceId.backgroundImage}
        alt={cart.serviceId.name}
        style={{
          width: '200px',  // full width for responsiveness
          height: '200px', // Adjust height as per your need
          objectFit: 'cover',
          objectPosition: 'center',
        }}
      />
    }
  >
    <div className="p-4 flex flex-col justify-between flex-grow">
      <div>
        <h3 className="text-lg lg:text-xl font-semibold mb-2 font-yusei">{cart.serviceId.name}</h3>
        {(selectedDateTime || cart.date) && (
          <div className="text-sm text-gray-600 mb-4">
            <CalendarOutlined className="mr-1" />
            {dayjs(selectedDateTime || cart.date).format("dddd DD MMMM YYYY")}
            <br />
            <ClockCircleOutlined className="mr-1" />
            {dayjs(selectedDateTime || cart.date).format("HH:mm")}
          </div>
        )}
      </div>
      <div className="flex flex-col space-y-2 lg:space-y-0 lg:flex-row lg:space-x-4 mt-4">
        <Button
          onClick={() => deleteCart()}
          icon={<DeleteOutlined />}
          className="w-full lg:w-auto"
          type="primary"
          danger
        >
          Supprimer
        </Button>
        <Button
          className={`w-full lg:w-auto  ${!cart.date ? "animate-pulse border-jb-primary " : "bg-jb-primary"}`}
          onClick={() => openNewSubscriptionFormModal(cart.serviceId)}
        >
          {!cart.date ? "Choisir un créneau" : "Modifier le rendez-vous"}
        </Button>
      </div>
    </div>
  </Card>

  );
};

export default CartItem;
