import { useSidebar } from "../../../contexts/SidebarContext";
import SideNavLink from "./SideNavLink";

interface SideNavSectionProps {
  title?: string;
  links: Array<{
    id: string;
    label: string;
    route?: string;
    icon: React.ReactNode;
  }>;
}

const SideNavSection: React.FC<SideNavSectionProps> = ({ title, links }) => {
  const { expanded } = useSidebar();

  return (
    <div className="section">
      <h4 className={`overflow-hidden ${expanded ? "w-52 ml-3" : "w-0"} section-title`}>
        {title}
      </h4>
      {links.map((link) => (
        <SideNavLink key={link.id} link={link} />
      ))}
    </div>
  );
};

export default SideNavSection;
