import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../common/store";

interface DateState {
  selectedDate: Date | null;
  selectedHour: string | null;
}

const initialState: DateState = {
  selectedDate: null,
  selectedHour: null,
};

export const dateSlice = createSlice({
  name: "date",
  initialState,
  reducers: {
    setSelectedDate: (state, action: PayloadAction<Date | null>) => {
      state.selectedDate = action.payload;
    },
    setSelectedHour: (state, action: PayloadAction<string | null>) => {
      state.selectedHour = action.payload;
    },
  },
});

// selector
export const selectSelectedDate = (state: RootState) =>
  state.dateService.selectedDate;
export const selectSelectedHour = (state: RootState) =>
  state.dateService.selectedHour;

export const { setSelectedDate, setSelectedHour } = dateSlice.actions;

export default dateSlice.reducer;
