import { DeleteOutlined, EditOutlined, FormOutlined, IdcardOutlined } from "@ant-design/icons";
import { MenuProps } from "antd";
import { Appointment } from "../../Appointment/types/modelAppointment";

export enum MenuItemContactKeysEnum {
  editAppointment = 'editAppointment',
  deleteAppointment = 'deleteAppointment'
}

export const getAdminAppointmentMenuItems = (appointment: Appointment, onItemClick: (appointment: Appointment, infos: any) => void): MenuProps['items'] => ([
  {
    key: '1',
    type: 'group',
    label: 'Actions',
    children: [
      {
        label: 'Modifier',
        key: MenuItemContactKeysEnum.editAppointment,
        icon: <EditOutlined />,
        onClick: (infos: any) => onItemClick(appointment, infos),
      },
      {
        label: 'Supprimer',
        key: MenuItemContactKeysEnum.deleteAppointment,
        icon: <DeleteOutlined />,
        onClick: (infos: any) => onItemClick(appointment, infos),
      },
    ],
  },
]);

