import { useContext } from "react";
import { useDispatch } from "react-redux";
import {
  logoutAction,
  selectToken,
  selectUser,
} from "../../../Auth/state/auth.slice";
import { SidebarProvider, useSidebar } from "../../contexts/SidebarContext"; // Importez le contexte
import { useAppSelector } from "../../hooks";
import "./SideNav.css";
import SideNavFooter from "./components/SideNavFooter";
import SideNavHeader from "./components/SideNavHeader";
import SideNavSection from "./components/SideNavSection";
import ListAppointmentSVG from "./icons/sidenavIcons/list_appointment";
import LogoutSvg from "./icons/sidenavIcons/logout";
import LogsSVG from "./icons/sidenavIcons/logs";
import Logo from "../Logo";
import { ROUTES } from "../../router";

export default function SideNavClient() {
  return (
    <SidebarProvider>
      <SideNavContent />
    </SidebarProvider>
  );
}

const SideNavContent = () => {
  const dispatch = useDispatch();
  const user = useAppSelector(selectUser);
  const token = useAppSelector(selectToken);

  // const { cartItemCount } = useContext(CartContext) || { cartItemCount: 0 };

  const { expanded, toggleSidebar } = useSidebar();

  const sidebarLinks = [
    {
      id: "dashboard",
      label: "Dashboard",
      route: ROUTES.ESTH.DASHBOARD,
      icon: <ListAppointmentSVG />,
    },
    {
      id: "logs",
      label: "Journals de logs",
      route: ROUTES.ESTH.LOGS,
      icon: <LogsSVG />,
    },
    {
      id: "logout",
      label: "Déconnexion",
      onClick: () => dispatch(logoutAction()),
      icon: <LogoutSvg />,
    },
  ];

  return (
    <aside id="sidebar">
    <nav
      className={`border-r shadow-sm sidebar  ${expanded ? "expanded" : "collapsed"}`}
    >
        <SideNavHeader expanded={expanded} setExpanded={toggleSidebar} />
        <ul className="flex-1 px-3">
          <div className="logo">
          <Logo expanded={expanded} setExpanded={toggleSidebar} width={200} height={200} logoSrc="/logos/logo.png"  />
          </div>
          <hr />
          <SideNavSection
            title="Prise de rendez-vous"
            links={sidebarLinks.filter(
              (link) => link.id !== "profile" && link.id !== "logout"
            )}
          />
        </ul>
        <SideNavFooter user={user} dispatch={dispatch} />
      </nav>
    </aside>
  );
};
