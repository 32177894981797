import PageCanvas from "../../common/components/Panels/PageCanvas";
import TermsAndConditions from "./TermsAndConditions";
import TermOfUse from "./TermsAndConditions";

const TermsAndConditionsPage = () => {
  return (
    <>
      <PageCanvas title="Conditions Générales de ventes" titleAlignment="center">
        <PageCanvas.Content>
          <TermsAndConditions />
        </PageCanvas.Content>
      </PageCanvas>
    </>
  );
};

export default TermsAndConditionsPage;
