import React from "react";

const LogoutSvg = ({color = 'black'}) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.615 20C6.155 20 5.771 19.846 5.463 19.538C5.155 19.23 5.00067 18.8457 5 18.385V5.615C5 5.155 5.15433 4.771 5.463 4.463C5.77167 4.155 6.15567 4.00067 6.615 4H13.019V5H6.615C6.46167 5 6.32067 5.064 6.192 5.192C6.06333 5.32 5.99933 5.461 6 5.615V18.385C6 18.5383 6.064 18.6793 6.192 18.808C6.32 18.9367 6.461 19.0007 6.615 19H13.019V20H6.615ZM17.462 15.538L16.76 14.819L19.079 12.5H10.192V11.5H19.079L16.759 9.18L17.462 8.462L21 12L17.462 15.538Z"
        fill={color}
      />
    </svg>
  );
};

export default LogoutSvg;
