import React from 'react';
import TermOfUseSections from './TermsAndConditionsSections';
import TermsAndConditionsSections from './TermsAndConditionsSections';

type Section = {
  id: string;
  title: string;
  content: string[];
};

type ConditionsVenteProps = {
  sections: Section[];
};

const conditionsVenteSections: Section[] = [
  {
    id: '0',
    title: "1 - Objet",
    content: [
      `Les présentes conditions régissent la vente des services de l'institut d'esthétique, y compris la prise de rendez-vous en ligne. 
      `,
    ]
  },
  {
    id: '1',
    title: "2 - Prix",
    content: [
      `Les prix sont affichés en euros et incluent toutes les taxes. Le prix applicable est celui en vigueur lors de la réservation.`,
    ]
  },
  {
    id: '2',
    title: "3 - Paiement",
    content: [
      `Le paiement doit être effectué en ligne par carte bancaire ou via un autre moyen sécurisé. La réservation est validée après paiement. Le paiement total de la prestation aura lieu dans l'institut`,
    ]
  },
  {
    id: '3',
    title: "4 - Annulation",
    content: [
      `Un accompte sera demandé pour valider la réservation. Dépassé le délai de 48h avant le rendez-vous, l'acompte ne sera pas remboursé.`,
    ]
  },
  {
    id: '4',
    title: "5 - Politique de remboursement",
    content: [
      `Les remboursements sont possibles pour les annulations faites avant 48 heures, le remboursement s'effectue entre 5 et 10 jours ouvrés dans les conditions de nos partenaires Stripe.`,
    ]
  },
  {
    id: '5',
    title: "6 - Responsabilité",
    content: [
      `L'institut n'est pas responsable des dommages indirects causés par l'utilisation de la plateforme ou du site web.`,
    ]
  },
  {
    id: '6',
    title: "7 - Données personnelles",
    content: [
      `Les données personnelles des utilisateurs sont collectées pour le bon déroulement des services et conformément à la réglementation en vigueur.`,
    ]
  },
];

const TermsAndConditions: React.FC = () => {
  return (
    <div>
      <TermsAndConditionsSections sections={conditionsVenteSections} />
    </div>
  );
};

export default TermsAndConditions;
