import apiClient from "../../common/services/apiClient";
import { trackProgress } from "../../common/services/trackProgress";
import { AppAuth } from "../types";
import { User } from "../../User/types/modelUser";

const AUTH_PATH_API = "auth";

class AuthApi {
  login(email: string, password: string) {
    return trackProgress(
      apiClient.post<AppAuth>(`${AUTH_PATH_API}/login`, { email, password }),
      `POST_${AUTH_PATH_API}login`
    ).then((r) => ({
      token: r.data.token,
      user: new User(r.data.user),
    }));
  }

  refreshToken(token: string) {
    return trackProgress(
      apiClient.post<AppAuth>(`${AUTH_PATH_API}/refreshToken`, { token }),
      `POST_${AUTH_PATH_API}refreshToken`
    ).then((r) => ({
      token: r.data.token,
      user: new User(r.data.user),
    }));
  }

  register(
    firstName: string,
    lastName: string,
    mobile: string,
    email: string,
    password: string
  ) {
    return trackProgress(
      apiClient.post<AppAuth>(`${AUTH_PATH_API}/signup`, {
        firstName,
        lastName,
        mobile,
        email,
        password,
      }),
      `POST_${AUTH_PATH_API}signup`
    ).then((r) => ({
      token: r.data.token,
      user: new User(r.data.user),
    }));
  }

  // Verify email GET
  verifyEmail(token: string) {
    return trackProgress(
      apiClient.get(`${AUTH_PATH_API}/verify-email?token=${token}`),
      `GET_${AUTH_PATH_API}verify-email`
    );
  }
  
  requestPasswordReset(email: string) {
    return trackProgress(
      apiClient.post(`${AUTH_PATH_API}/request-password-reset`, email),
      `POST_${AUTH_PATH_API}request-password-reset`
    );
  }

  resetPassword(token: string, password: string) {
    return trackProgress(
      apiClient.post(`${AUTH_PATH_API}/reset-password`, { token, password }),
      `POST_${AUTH_PATH_API}reset-password`
    );
  }

  updateMe(data: Partial<AppAuth>) {
    return trackProgress(
      apiClient.put<User>(`${AUTH_PATH_API}/updateMe`, data),
      `PUT_${AUTH_PATH_API}updateMe`
    ).then((r) => new User(r.data));
  }

  // suppression de mon compte via mon id
  deleteMe() {
    return trackProgress(
      apiClient.delete(`${AUTH_PATH_API}/deleteMe`),
      `DELETE_${AUTH_PATH_API}deleteMe`
    );
  }
}

const authApi = new AuthApi();
export default authApi;
