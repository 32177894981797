import React, { useState } from 'react';
import { FilePdfOutlined, DownloadOutlined } from '@ant-design/icons';
import axios from 'axios';
import { Invoice } from './services/types/modelInvoice';

interface InvoiceCardProps {
  invoice: Invoice;
}

const InvoiceCard: React.FC<InvoiceCardProps> = ({ invoice }) => {
  const [isHovered, setIsHovered] = useState(false);

  const downloadInvoice = async (invoiceId: string, fileName: string) => {
    try {
      const response = await axios.get(`http://localhost:3002/api/invoices/${invoiceId}/download`, {
        responseType: 'blob', // Récupération des données binaires
      });

      // Création d'un lien de téléchargement
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Erreur lors du téléchargement du fichier PDF', error);
    }
  };

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => downloadInvoice(invoice._id!, invoice.fileName)}
      className="relative bg-white p-4 rounded-lg cursor-pointer shadow hover:shadow-lg transition-all duration-300 ease-in-out transform flex flex-col items-center space-y-3"
    >
      {/* Icône PDF qui disparaît au survol */}
      <FilePdfOutlined
        className={`text-4xl text-jb-timeline-delete transition-opacity duration-300 ${
          isHovered ? 'opacity-0' : 'opacity-100'
        }`}
      />

      {/* Nom de la facture */}
      <p
        className={`text-center font-yusei text-base font-semibold transition-transform duration-300 ${
          isHovered ? 'translate-y-[10px]' : ''
        }`}
      >
        {invoice.fileName}
      </p>

      {/* Icône de téléchargement (apparaît en dessous du nom et le fait remonter) */}
      <div
        className={`absolute transition-transform duration-300 transform ${
          isHovered ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'
        }`}
      >
        <DownloadOutlined className="text-4xl text-jb-timeline-delete" />
      </div>
    </div>
  );
};

export default InvoiceCard;
