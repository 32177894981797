import React from "react";

interface LogoProps {
  expanded?: boolean;
  setExpanded?: (expanded: boolean) => void;
  width?: string | number;
  height?: string | number;
  className?: string;
  logoSrc?: string;
  logoAlt?: string;
  smallLogoSrc?: string;
}

const Logo: React.FC<LogoProps> = ({
  expanded,
  setExpanded,
  width = 48,
  height = 48,
  className = "",
  logoSrc = "/logos/logo.png",
  logoAlt = "logo",
  smallLogoSrc = "/logos/logo-sm.png",
}) => {
  // Convert width and height to numbers if they are strings
  const numericWidth = typeof width === "number" ? width : parseFloat(width);
  const numericHeight = typeof height === "number" ? height : parseFloat(height);

  return (
    <div className={`flex mb-4 items-center justify-center h-16 ${className}`}>
      <img
        src={expanded ? logoSrc : smallLogoSrc}
        className="object-contain"
        alt={logoAlt}
        style={{
          width: expanded ? `${numericWidth}px` : `${numericWidth * 0.58}px`,
          height: expanded ? `${numericHeight}px` : `${numericHeight * 0.58}px`,
        }}
      />
    </div>
  );
};

export default Logo;
