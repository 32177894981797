import { MoreOutlined } from "@ant-design/icons";
import { Dropdown, Table } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import StatusBadge from "../../Appointment/StatusBadge";
import { AppointmentStatusEnum } from "../../Appointment/types/appointmentTypes";
import { Appointment } from "../../Appointment/types/modelAppointment";
import Fieldset from "../../common/components/Panels/Fieldset";
import { useAppDispatch } from "../../common/hooks";
import { ApiListResult } from "../../common/services/types";
import UserFullName from "../../User/components/UserFullName";
import adminApi from "../services/admin.api";
import { getAdminAppointmentMenuItems, MenuItemContactKeysEnum } from "./AdminListAppointment.misc";
import { openModalAction } from "../../common/state/modal.slice";
import AppointmentForm from "./Forms/AppointmentForm";
import ModalConfirm from "../../common/components/Modals/modal-confirm.component";
import { IUser } from "../../User/types/UserTypes";
import { IService } from "../../Services/services/types/serviceTypes";

interface DataType {
  _id: string;
  key: string;
  userId: IUser;
  serviceId: IService;
  service: string;
  date: string;
  status: string;
  action: string;
}

const AdminListAppointment: React.FC = () => {
  const dispatch = useAppDispatch();
  const [appointments, setAppointments] = useState<ApiListResult<Appointment>>();
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

  const fetchAppointmentsAdmin = async () => {
    try {
      const res = await adminApi.findAppointmentsAdmin({}, 1, 10);
      setAppointments(res);
    } catch (error) {
      console.log(error);
    }
  };

  const openModalDeleteAppointment = (appointment: Appointment) => {
    dispatch(
      openModalAction({
        component: (
          <ModalConfirm
            formCb={async (confirm) => {
              if (confirm === true) {
                await adminApi.deleteAppointment(appointment._id!);
                fetchAppointmentsAdmin();
              }
            }}
            confirmIsDanger
            body="Êtes vous sur de vouloir supprimer les contacts selectionnés ?"
          />
        ),
        options: { title: "Supprimer un contact" },
      })
    );
  };
  
  const itemClicked = (appointment: Appointment, infos: any) => {
    switch (infos.key) {
      case MenuItemContactKeysEnum.editAppointment:
        openModalEditAppointment(appointment);
        break;
      case MenuItemContactKeysEnum.deleteAppointment:
        openModalDeleteAppointment(appointment);
      break;

      default:
        break;
    }
  };


  const openModalEditAppointment = (appointment: Appointment) => {
    dispatch(
      openModalAction({
        component: <AppointmentForm appointment={appointment} />,
        options: { title: "Modifier un service" },
      })
    );
  };


  useEffect(() => {
    fetchAppointmentsAdmin();
  }, []);

  

  // Group appointments by date
  const groupedAppointments = appointments?.rows.reduce((acc, appointment) => {
    const date = dayjs(appointment.date).format("DD/MM/YYYY");
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push({
      key: appointment._id!,
      _id: appointment._id!,
      userId: appointment.userId!,
      serviceId: appointment.serviceId!,
      date: dayjs(appointment.date).format("HH:mm"),
      status: appointment.status as any,
      action: "Action",
      service: appointment.serviceId?.name,
    });
    return acc;
  }, {} as { [key: string]: DataType[] });


  const handleExpand = (expanded: boolean, record: any) => {
    if (expanded) {
      setExpandedRowKeys([record.key]);
    } else {
      setExpandedRowKeys([]);
    }
  };
  const columns: any = [
    {
      title: "Nom du client",
      render: (appointment: Appointment) => (
        <div>
          {appointment.userId ? <UserFullName user={appointment.userId} /> : "Utilisateur inconnu"}
        </div>
      ),
    },
    {
      title: "Service réservé",
      dataIndex: ["serviceId", "name"],
    },
    {
      title: "Date et heure",
      dataIndex: "date",
    },
    {
      title: "Statut",
      dataIndex: "status",
      render: (text: string, appointment: Appointment) => (
        <StatusBadge status={appointment.status} />
      ),
    },
    {
      title: <div className="text-center">Options</div>,
      key: "actions",
      render: (text: string, contact: any) => (
        <div className="text-center">
          <Dropdown menu={{ items: getAdminAppointmentMenuItems(contact, itemClicked) }}>
            <MoreOutlined />
          </Dropdown>
        </div>
      ),
    },
  ];
  
  

  return (
    <Fieldset title="Vue d'ensemble des rendez-vous : Admin">
      <Table
        rowKey="key"
        expandable={{
          expandedRowRender: (record) => (
            <Table
              rowKey="key"
              columns={columns}
              dataSource={groupedAppointments![record.date]}
              pagination={false}
              scroll={{ x: "max-content" }}
            />
          ),
          onExpand: handleExpand,
        }}
        columns={[
          {
            title: "Date",
            dataIndex: "date",
            key: "date",
            render: (text: string, record: any) => (
              <div>
                {record.date}
              </div>
            ),
          },
        ]}
        dataSource={Object.keys(groupedAppointments || {}).map(date => ({
          key: date,
          date,
        }))}
        expandedRowKeys={expandedRowKeys}
        scroll={{ x: "max-content" }}
      />
    </Fieldset>
  );
};

export default AdminListAppointment;

