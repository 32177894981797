import {
  DeleteOutlined,
  EditOutlined
} from "@ant-design/icons";
import { MenuProps } from "antd";
import { User } from "../../User/types/modelUser";
import { Service } from "../../Services/services/types/modelService";

export enum MenuItemContactKeysEnum {
  editService = "editService",
  deleteService = "deleteService",
}

export const getServiceMenuItems = (
  service: Service,
  onItemClick: (service: Service, infos: any) => void
): MenuProps["items"] => [
  {
    key: '1',
    type: 'group',
    label: 'Actions',
    children: [
      {
        label: "Modifier",
        key: MenuItemContactKeysEnum.editService,
        icon: <EditOutlined />,
        onClick: (infos: any) => onItemClick(service, infos),
      },
      {
        label: "Supprimer",
        key: MenuItemContactKeysEnum.deleteService,
        icon: <DeleteOutlined />,
        onClick: (infos: any) => onItemClick(service, infos),
      },
    ],
  },
];
