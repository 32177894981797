import { Outlet } from "react-router-dom";

const WelcomeLayout = () => {
  const d = new Date().getFullYear();

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 h-screen max-h-screen">

        {/* Section gauche, où le contenu est affiché */}
        <div className="bg-gray-50 h-full overflow-y-auto">
          <div className="p-frame flex h-full items-center justify-center">
            <div>
              <Outlet />
            </div>
          </div>
        </div>

        {/* Section droite avec l'image de fond */}
        <div className="relative h-full flex flex-col justify-between p-frame hidden md:block ">
          {/* Cache l'image sur mobile, affiche-la sur les écrans moyens et plus grands */}
          <img
            alt="background"
            src="/images/bg.jpg"
            className="hidden md:block object-cover w-full h-full"
          />
          <span className="text-white absolute bottom-4 md:bottom-20 right-4 md:right-20">
            LolaBeauty copyright {d}
          </span>
        </div>
      </div>
    </>
  );
};

export default WelcomeLayout;
