import colors from "tailwindcss/colors";
import { AppointmentStatusEnum } from "./Appointment/types/appointmentTypes";
import { TimelineStatusEnum } from "./Esth/Logs/types/LogTypes";

const tailwindTheme = {
  extend: {
    screen:{
      'sm': '640px',
      'md': '768px',
      'lg': '1024px',
      'xl': '1280px',
      '2xl': '1536px',
    },
   
    borderWidth: {
      "1": "1px",
      "3": "3px",
      "6": "6px",
    },
    zIndex: {
      999999: '999999',
      99999: '99999',
      9999: '9999',
      999: '999',
      99: '99',
      9: '9',
      1: '1',
    },
    screens: {
      "2xl": { max: "1300px" },
      print: { raw: "print" },
      // => @media  print { ... }
    },
  },
  colors: {
    transparent: "transparent",
    white: "#fff",
    black: "#000",
    gray: colors.gray,
    slate: colors.slate,
    jb: {
      primary: "#c79d79",
      secondary: '#4c647a',
      status: {
        [AppointmentStatusEnum.cancelled]: '#e3342f',
        [AppointmentStatusEnum.confirmed]: '#38c172',
        [AppointmentStatusEnum.finished]: '#6c849a',
      },
      timeline: {
        [TimelineStatusEnum.create]: '#38c172',
        [TimelineStatusEnum.read]: '#3490dc',
        [TimelineStatusEnum.update]: '#f6993f',
        [TimelineStatusEnum.delete]: '#e3342f',
      }
    },
  },
  fontFamily: {
    yusei: ['Yusei Magic', 'sans-serif'],
    antic: ['Antic Didone', 'serif'],
  },
  container: {
    center: true,
    padding: "2rem",
  },
};

export default tailwindTheme;
