import { Link } from 'react-router-dom';
import LogoIcon from '../../images/logo/logo-icon.svg';
import UserBar from '../UserBar';


const Header = (props: {
  sidebarOpen: string | boolean | undefined;
  setSidebarOpen: (arg0: boolean) => void;
}) => {
  return (
    <header className="sticky top-0 flex w-full bg-white shadow-sm" style={{ height: '5%' }}>
      <div className="flex items-center justify-end w-full max-w-screen-xl px-4 mx-auto">
        <UserBar />
      </div>
    </header>
  );
};

export default Header;
