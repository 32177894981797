// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-link:hover {
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 0.375rem;
    --tw-bg-opacity: 1;
    background-color: rgb(199 157 121 / var(--tw-bg-opacity));
    padding: 0.25rem;
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
    transition-property: all;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
}

`, "",{"version":3,"sources":["webpack://./src/common/components/Footer/Footer.css"],"names":[],"mappings":"AACE;IAAA,uBAAoD;IAApD,kBAAoD;IAApD,uBAAoD;IAApD,kBAAoD;IAApD,yDAAoD;IAApD,gBAAoD;IAApD,oBAAoD;IAApD,gDAAoD;IACpD,wBAA6C;IAA7C,0BAA6C;IAA7C;AADoD","sourcesContent":[".footer-link:hover {\n  @apply p-1 rounded-md bg-jb-primary text-white w-fit;\n  @apply transition-all duration-300 ease-in-out \n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
