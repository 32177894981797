import { CheckOutlined } from "@ant-design/icons";
import { Button, Form, Input } from "antd";
import { useAppDispatch } from "../../../common/hooks";
import { showErrorNotification, showSuccessNotification } from "../../../common/services/notificationService";
import { CloseAnyModalAction } from "../../../common/state/modal.slice";
import { User } from "../../../User/types/modelUser";
import adminApi from "../../services/admin.api";

type PropsType = {
  user?: User | null;
  formCb?: (data?: User) => void;
};

const UserForm = ({ user, formCb }: PropsType) => {
  const dispatch = useAppDispatch();

  const saveUser = async (formData: User) => {
    try {
      if (user) {
        await adminApi.updateUser(user._id!, formData);
        showSuccessNotification("Succès", "Utilisateur modifié avec succès");        
      }
      else {
        showErrorNotification("Erreur", "Une erreur est survenue");
      }
      dispatch(CloseAnyModalAction());
      
    
      formCb && formCb(formData);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Form<User>
      name="basic"
      initialValues={user || {}}
      layout="vertical"
      className="form"
      onFinish={saveUser}
    >
      <Form.Item
        label="Prénom"
        name="firstName"
        rules={[{ required: true, message: "Veuillez saisir le prénom" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Nom"
        name="lastName"
        rules={[{ required: true, message: "Veuillez saisir le nom" }]}
      >
        <Input />
      </Form.Item>
      {/* mobile */}
      <Form.Item
        label="Téléphone"
        name="mobile"
        rules={[{ required: true, message: "Veuillez saisir le téléphone" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Email"
        name="email"
        rules={[{ required: true, message: "Veuillez saisir l'email" }]}
      >
        <Input />
      </Form.Item>

      <div>
        <Form.Item>
          <Button
            className="btn-void"
            htmlType="submit"
            icon={<CheckOutlined />}
          >
            Enregistrer
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
};

export default UserForm;
