import {
  DeleteOutlined,
  EditOutlined
} from "@ant-design/icons";
import { MenuProps } from "antd";
import { User } from "../../User/types/modelUser";

export enum MenuItemContactKeysEnum {
  editUser = "editUser",
  deleteUser = "deleteUser",
}

export const getUserMenuItems = (
  user: User,
  onItemClick: (user: User, infos: any) => void
): MenuProps["items"] => [
  {
    key: '1',
    type: 'group',
    label: 'Actions',
    children: [
      {
        label: "Modifier",
        key: MenuItemContactKeysEnum.editUser,
        icon: <EditOutlined />,
        onClick: (infos: any) => onItemClick(user, infos),
      },
      {
        label: "Supprimer",
        key: MenuItemContactKeysEnum.deleteUser,
        icon: <DeleteOutlined />,
        onClick: (infos: any) => onItemClick(user, infos),
      },
    ],
  },
];
