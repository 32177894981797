import { useContext } from "react";
import { useDispatch } from "react-redux";
import { selectToken, selectUser } from "../../../Auth/state/auth.slice";
import { useCart } from "../../contexts/CartContext";
import { SidebarProvider, useSidebar } from "../../contexts/SidebarContext"; // Importez le contexte
import { useAppSelector } from "../../hooks";
import Logo from "../Logo";
import "./SideNav.css";
import SideNavFooter from "./components/SideNavFooter";
import SideNavHeader from "./components/SideNavHeader";
import SideNavSection from "./components/SideNavSection";
import AppointmentSVG from "./icons/sidenavIcons/appointment";
import ListAppointmentSVG from "./icons/sidenavIcons/list_appointment";
import ProfileSvg from "./icons/sidenavIcons/profile";
import ServiceSvg from "./icons/sidenavIcons/service";
import { ROUTES } from "../../router";
import InvoiceSvg from "./icons/sidenavIcons/invoice";

export default function SideNavClient() {
  return (
    <SidebarProvider>
      <SideNavContent />
    </SidebarProvider>
  );
}

const SideNavContent = () => {

  const dispatch = useDispatch();
  const user = useAppSelector(selectUser);

  const { cart } = useCart();


  const { expanded, toggleSidebar } = useSidebar();

  const sidebarLinks = [
    {
      id: "dashboard",
      label: "Mes rendez-vous",
      route: ROUTES.CLIENT.DASHBOARD,
      icon: <ListAppointmentSVG />,
    },
    {
      id: "services",
      label: "Prestations",
      route: ROUTES.CLIENT.SERVICES,
      icon: <ServiceSvg />,
    },
    {
      id: "cart",
      label: "Panier",
      route: ROUTES.CLIENT.CART,
      icon: <AppointmentSVG />,
      badge: cart.count,
    },
    {
      id: "profile",
      label: "Profil",
      route: ROUTES.CLIENT.PROFILE,
      icon: <ProfileSvg />,
    },
    {
      id: "invoice",
      label: "Factures",
      route: ROUTES.CLIENT.INVOICE,
      icon: <InvoiceSvg />,
    }
  ];

  return (
    <aside id="sidebar">
      <nav
        className={`border-r shadow-sm sidebar  ${expanded ? "expanded" : "collapsed"}`}
      >
        {" "}
        <SideNavHeader expanded={expanded} setExpanded={toggleSidebar} />
        <ul className="flex-1 px-3">
          <div className="logo">
            <Logo expanded={expanded} setExpanded={toggleSidebar} width={200} height={200} logoSrc="/logos/logo.png" />
          </div>{" "}
          <hr />
          <SideNavSection
            title="Prise de rendez-vous"
            links={sidebarLinks.filter(
              (link) => link.id !== "profile" && link.id !== "logout"
            )}
          />
          <SideNavSection
            title="Paramètres"
            links={sidebarLinks.filter((link) => link.id === "profile")}
          />
        </ul>
        <SideNavFooter user={user} dispatch={dispatch} />
      </nav>
    </aside>
  );
};
