
import { useTranslation } from "react-i18next";
import LoginForm from "./ResetPasswordForm";
import { useAppSelector } from "../../../common/hooks";
import { selectIsLoggedIn, selectUser } from "../../state/auth.slice";
import { Navigate } from "react-router-dom";
import { ROUTES } from "../../../common/router";
import { UserRolesEnum } from "../../../User/types/UserTypes";
import ResetPasswordForm from "./ResetPasswordForm";

const ResetPasswordPage = () => {
  const { t } = useTranslation();
  return <>
    <h1 className="titleForm">{t('UI.RESET_PASSWORD_PAGE.title')}</h1>
    <ResetPasswordForm/>
  </>
}

export default ResetPasswordPage;