import { CaretLeftFilled, CaretRightFilled } from "@ant-design/icons";
import { Button } from "antd";
import { useMediaQuery } from "react-responsive";


interface SideNavHeaderProps {
  expanded: boolean;
  setExpanded: (expanded: boolean) => void;
}

const SideNavHeader: React.FC<SideNavHeaderProps> = ({ expanded, setExpanded }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 500px)" });
  
  return (
    <div className="relative p-4 pb-2 flex justify-between items-center">
      <Button
        shape="circle"
        onClick={() => setExpanded(!expanded)}
        className="p-1.5 rounded-lg bg-gray-50 hover:bg-gray-100 absolute top-5 -right-3"
        icon={expanded ? <CaretLeftFilled /> : <CaretRightFilled />}
        disabled={isMobile} // Add this line to disable the button on mobile view
      />
    </div>
  );
};

export default SideNavHeader;
