import { loadStripe } from "@stripe/stripe-js";
import appointmentApi from "../../Appointment/services/appointment.api";
import { AppointmentStatusEnum } from "../../Appointment/types/appointmentTypes";
import { defaultPagination } from "../../common/app-constants";
import ApiService from "../../common/services/apiService";
import { ApiListResult } from "../../common/services/types";
import { Service } from "../../Services/services/types/modelService";
import { ICart } from "../types/cartTypes";
import { Cart } from "../types/modelCart";
import { useCart } from "../../common/contexts/CartContext";

const CART_PATH_API = "cart";

class CartApi extends ApiService<ICart> {
  constructor() {
    super(CART_PATH_API);
  }


  fetchCart = (
    query = {},
    page = defaultPagination.page,
    pageSize = defaultPagination.pageSize
  ): Promise<{ count: number; rows: Cart[] }> => {
    return this.find({ query, page, pageSize }).then((res) => {
      if (!res || !res.rows) {
        throw new Error("Invalid response structure");
      }
      return {
        count: res.count,
        rows: res.rows.map((r) => new Cart(r)),
      };
    });
  };

  addServiceToCart = (serviceId: string, date: Date): Promise<Service> => {
    return this.custom<Service>("post", "", {}, { serviceId, date }).then(
      (r) => new Service(r)
    );
  };

  updateCartDate = (cartId: string, date: Date): Promise<Cart> => {
    return this.custom<Cart>("put", `${cartId}/update-date`, {}, { date }).then(
      (r) => new Cart(r)
    );
  };

  removeFromCart = (cartId: string): Promise<Cart> => {
    return this.delete(cartId).then((res) => new Cart(res));
  };

  removeAllFromCart = (): Promise<ApiListResult<Cart[]>> => {
    return this.custom<ApiListResult<Cart[]>>("delete", "remove-all", {});
  };


  makePayment = async (cart: ApiListResult<ICart>) => {
    const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY as string);
    const body = {
      products: cart?.rows,
    };

    const response = await this.custom<{ sessionId: string }>(
      "post",
      "create-checkout-session",
      {},
      body
    );


    await stripe?.redirectToCheckout({ sessionId: response.sessionId });
  };

  createAppointments = async (cart: ApiListResult<ICart>): Promise<boolean> => {
    try {
      for (const item of cart.rows) {
        const appointment = {
          date: item.date,
          serviceId: item.serviceId._id,
          status: AppointmentStatusEnum.confirmed,
        };
        const result = await appointmentApi.createAppointment(appointment as any);
        if (!result) {
          throw new Error("Failed to create appointment");
        }
      }
      // supprimer les services du panier si tous les rendez-vous ont été créés
      // await this.removeAllFromCart();
      return true;
    } catch (error) {
      console.error("Error creating appointments:", error);
      return false;
    }
  };
}

export default new CartApi();
