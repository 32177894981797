import { RouteObject } from 'react-router-dom';

import LoginPage from './components/Login/LoginPage';
import RegisterPage from './components/Register/RegisterPage';
import AskNewPasswordPage from './components/AskNewPassword/AskNewPasswordPage';
import WelcomeLayout from '../common/layouts/WelcomeLayout/WelcomeLayout';
import ResetPasswordPage from './components/ResetPassword/ResetPasswordPage';
import VerifyEmail from './components/VerifyEmail/VerifyEmail';
import VerifyEmailPage from './components/VerifyEmail/VerifyEmailPage';

const AuthRoutes: RouteObject[] = [
    {
        path: '',
        element: <WelcomeLayout></WelcomeLayout>,
        children: [
            {
                path: 'login',
                element: <LoginPage></LoginPage>,
            },
            {
                path: 'register',
                element: <RegisterPage></RegisterPage>,
            },
            {
                path: 'new-password',
                element: <AskNewPasswordPage></AskNewPasswordPage>,
            },
            {
                path: 'reset-password',
                element: <ResetPasswordPage></ResetPasswordPage>,
            },
            {
                path: 'verify-email',
                element: <VerifyEmailPage></VerifyEmailPage >,
            }

        ],
    },
];

export default AuthRoutes;
