import React from 'react';
import { Collapse } from 'antd';
import type { CollapseProps } from 'antd';
import 'antd/dist/reset.css'; // Import the Ant Design styles

type Section = {
  id: string;
  title: string;
  content: string[];
};

type TermOfUseSectionsProps = {
  sections: Section[];
};

const { Panel } = Collapse;
const CustomPanelHeader: React.FC<{ title: string }> = ({ title }) => (
  <span className="font-bold">{title}</span>
);

const TermOfUseSections: React.FC<TermOfUseSectionsProps> = ({ sections }) => {
  return (
    <div className="container mx-auto p-4">
      <Collapse
        // tous doit être ouvert par défaut
        defaultActiveKey={[
          // mapper la propriété id de chaque section
          // ...sections.map((section) => section.id),

        ]}
        expandIconPosition="right"
        className="w-full"
      >
        {sections.map((section, index) => (
          <Panel header={<CustomPanelHeader title={section.title} />} key={index} className="border rounded-lg shadow-md">            {section.content.map((paragraph, i) => (
            <p key={i} className="text-sm mb-2">
              {paragraph}
            </p>
          ))}
          </Panel>
        ))}
      </Collapse>
    </div>
  );
};

export default TermOfUseSections;
