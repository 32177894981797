import React, { useMemo } from "react";
import { Avatar, Dropdown, Menu, Space, Typography } from "antd";
import {
  UserOutlined,
  LogoutOutlined,
  CaretDownFilled,
} from "@ant-design/icons";
import { logoutAction, selectUser } from "../../Auth/state/auth.slice";
import { useAppDispatch, useAppSelector } from "../hooks";
import logout from "../components/SideNav/icons/sidenavIcons/logout";

const { Text } = Typography;

const ProfileDropdown = () => {
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();

  // méthode pour avoir les initiales du prénom et du nom du user
  const getInitials = (name: string) => {
    const names = name.split(" ");
    let initials = names[0].substring(0, 1).toUpperCase();
    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };

  // générer une couleur aléatoire pour l'avatar (une seule fois)
  const avatarColor = useMemo(() => {
    return "#" + Math.floor(Math.random() * 16777215).toString(16);
  }, []); // La couleur est générée une seule fois

  const menu = (
    <Menu>
      <Menu.Item key="profile" disabled>
        <Space>
          <Avatar style={{
            backgroundColor:
              user?.firstName && user?.lastName
                ? avatarColor
                : "#C79D79"
          }}>
            {
              user?.firstName && user?.lastName
                ? getInitials(`${user.firstName} ${user.lastName}`)
                : <UserOutlined />
            }
          </Avatar>
          <Text strong>{
            user?.firstName && user?.lastName
              ? `${user.firstName} ${user.lastName}`
              : "Utilisateur"
          }</Text>
        </Space>
      </Menu.Item>

      <Menu.Divider />
      {/* Email */}
      <Menu.Item key="email" disabled>
        <Text type="secondary">{user?.email}</Text>
      </Menu.Item>

      <Menu.Item key="logout" style={{ display: 'flex', alignItems: 'center' }}
        onClick={() => {
          dispatch(logoutAction());
        }}
      >
        <LogoutOutlined />
        <span style={{ marginLeft: '8px' }}>Déconnexion</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="flex space-x-2">
      <Dropdown overlay={menu} placement="bottomRight">
        <Avatar style={{
          backgroundColor:
            user?.firstName && user?.lastName
              ? avatarColor
              : "#C79D79",
          cursor: "pointer"
        }}>
          {
            user?.firstName && user?.lastName
              ? getInitials(`${user.firstName} ${user.lastName}`)
              : <UserOutlined />
          }

        </Avatar>
      </Dropdown>
    </div>
  );
};

export default ProfileDropdown;
