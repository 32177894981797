import { CheckOutlined } from "@ant-design/icons";
import { Button, DatePicker, Form, Input, Select } from "antd";
import { useEffect, useState } from "react";
import { Appointment } from "../../../Appointment/types/modelAppointment";
import { useAppDispatch } from "../../../common/hooks";
import { showErrorNotification, showSuccessNotification } from "../../../common/services/notificationService";
import { CloseAnyModalAction } from "../../../common/state/modal.slice";
import { Service } from "../../../Services/services/types/modelService";
import adminApi from "../../services/admin.api";
import dayjs from "dayjs";
import { IUser } from "../../../User/types/UserTypes";
import { AppointmentStatusEnum, IAppointment } from "../../../Appointment/types/appointmentTypes";

type PropsType = {
  appointment?: Appointment | null;
  formCb?: (data?: Partial<IAppointment>) => void;
};

const AppointmentForm = ({ appointment, formCb }: PropsType) => {
  const dispatch = useAppDispatch();

  // Récupérer la liste des services
  const [services, setServices] = useState<Service[]>([]);
  useEffect(() => {
    adminApi.findServicesAdmin().then((res) => {
      setServices(res.rows);
    });
  }, []);

  // Simulation pour récupérer la liste des utilisateurs
  const [users, setUsers] = useState<IUser[]>([]);
  useEffect(() => {
    adminApi.findUsersAdmin().then((res) => {
      setUsers(res.rows);
    });
  }, []);

  const saveAppointment = async (formData: Partial<IAppointment>) => {
    try {
      const formattedData = {
        ...formData,
        date: dayjs(formData.date).toISOString(), // Format ISO pour la date
      };

      if (appointment) {
        await adminApi.updateAppointment(appointment._id!, formattedData as any);
        showSuccessNotification("Succès", "Rendez-vous modifié avec succès");
      } else {
        await adminApi.createAppointment(formData);
        showSuccessNotification("Succès", "Rendez-vous créé avec succès");
      }

      dispatch(CloseAnyModalAction());
      formCb && formCb(formData);
    } catch (error) {
      console.log(error);
      showErrorNotification("Erreur", "Une erreur est survenue lors de l'enregistrement");
    }
  };

  return (
    <Form<Partial<Appointment>>
      name="appointmentForm"
      initialValues={{
        serviceId: appointment?.serviceId?._id, // Sélectionner l'ID du service
        userId: appointment?.userId?._id, // Sélectionner l'utilisateur si présent
        status: appointment?.status,
      }}
      layout="vertical"
      className="form"
      onFinish={saveAppointment}
    >
      {/* Utilisateur */}
      {/* Afficher uniquement dans le cas d'une création */}
      <Form.Item
        label="Utilisateur"
        name="userId"
        rules={[{ required: true, message: "Veuillez sélectionner un utilisateur" }]}
      >
        <Select
          showSearch
          placeholder="Sélectionner un utilisateur"
          filterOption={(input, option) =>
            (option?.label as string).toLowerCase().includes(input.toLowerCase())
          }
          optionLabelProp="label"
        >
          {users.map((user) => (
            <Select.Option
              key={user._id}
              value={user._id}
              label={`${user.firstName} ${user.lastName}`} // Utiliser label pour le texte affiché
            >
              {user.firstName} {user.lastName}
            </Select.Option>
          ))}
        </Select>

      </Form.Item>

      {/* Service réservé */}
      <Form.Item
        label="Service réservé"
        name="serviceId"
        rules={[{ required: true, message: "Veuillez sélectionner un service" }]}
      >
        <Select
          placeholder="Sélectionner un service"
          allowClear
          showSearch
        >
          {services.map((service) => (
            <Select.Option key={service._id} value={service._id}>
              {service.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="Date"
        name="date"
        rules={[{ required: true, message: "Veuillez sélectionner une date" }]}
      >
        <DatePicker
          showTime
          format="DD/MM/YYYY HH:mm"
          style={{ width: "100%" }}
          placeholder="Sélectionner une date"
        />
      </Form.Item>
      {/* statut */}
      <Form.Item
        label="Statut"
        name="status"
        rules={[{ required: true, message: "Veuillez sélectionner un statut" }]}
      >
        <Select
          placeholder="Sélectionner un statut"
          allowClear
          showSearch
        >
          <Select.Option value={AppointmentStatusEnum.finished}>Terminé</Select.Option>
          <Select.Option value={AppointmentStatusEnum.confirmed}>Confirmé</Select.Option>
          <Select.Option value={AppointmentStatusEnum.cancelled}>Annulé</Select.Option>
        </Select>
      </Form.Item>
      {/* Actions */}
      <div>
        <Form.Item>
          <Button
            className="btn-void"
            htmlType="submit"
            icon={<CheckOutlined />}
          >
            Enregistrer
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
};

export default AppointmentForm;
