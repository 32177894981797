import { defaultPagination } from "../../../common/app-constants";
import ApiService from "../../../common/services/apiService";
import { ApiListResult } from "../../../common/services/types";
import { ILog } from "../types/LogTypes";
import { Log } from "../types/modelLog";


const LOG_PATH_API = "logs/";

class LogApi extends ApiService<ILog> {
  constructor() {
    super(LOG_PATH_API);
  }


  findLogs = (
    query = {},
    page = defaultPagination.page,
    pageSize = defaultPagination.pageSize
  ): Promise<{ count: number; rows: Log[] }> => {
    return this.find({ query, page, pageSize }).then((res) => {
      return {
        count: res.count,
        rows: res.rows.map((r) => new Log(r)),
      };
    });
  };

  deleteLogAndAppointment = (logId: string): Promise<Log> => {
    return this.delete(logId).then((res) => new Log(res));
  };
}

const logApi = new LogApi();

export default logApi;
