import { PoweroffOutlined } from "@ant-design/icons";
import { Button, Tooltip, Badge } from "antd";
import { logoutAction } from "../../../../Auth/state/auth.slice";
import { useSidebar } from "../../../contexts/SidebarContext";

interface SideNavFooterProps {
  user: any;
  dispatch: any;
}

const SideNavFooter: React.FC<SideNavFooterProps> = ({ user, dispatch }) => {
  const { expanded } = useSidebar();

  return (
    <div
      className={`flex border-t p-3 ${expanded
        ? "justify-between items-center"
        : "flex-col items-center"
        } overflow-hidden transition-all`}
    >
      {/* Badge visible lorsque la barre latérale est fermée */}
      <div className={`flex justify-between items-center overflow-hidden ${expanded ? "w-52 ml-3" : "w-auto"}`}>
        {expanded ? (
          // Affichage lorsque la barre latérale est étendue
          <div className="leading-4">
            <h4 className="font-semibold">
              {user?.firstName} {user?.lastName}
            </h4>
            <span className="text-xs text-gray-600">{user?.email}</span>
          </div>
        ) : (
          // Affichage lorsque la barre latérale est fermée
          <span className="text-xs font-semibold text-gray-600">
            {user?.firstName} {user?.lastName}
          </span>

        )}
      </div>

      {/* Bouton de déconnexion, placé en dessous si la barre latérale est fermée */}
      <div className={`mt-${expanded ? "0" : "2"}`}>
        <Tooltip title="Déconnexion" placement="right">
          <Button
            onClick={() => dispatch(logoutAction())}
            icon={<PoweroffOutlined />}
            shape="circle"
          />
        </Tooltip>
      </div>
    </div>
  );
};

export default SideNavFooter;
