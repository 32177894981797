import React from 'react';
import ChartOfConfidentialitySections from './ChartOfConfidentialitySections';
type Section = {
  id: string;
  title: string;
  content: string[];
};

type MentionsLegalesProps = {
  sections: Section[];
};
const confidentialitySections: Section[] = [
  {
    id: '0',
    title: "1 - Collecte des Données",
    content: [
      `Nous collectons uniquement les informations nécessaires pour le bon fonctionnement de l'application, telles que les données de réservation, les coordonnées, et les préférences des utilisateurs.`
    ]
  },
  {
    id: '1',
    title: "2 - Utilisation des Données",
    content: [
      `Les données collectées sont utilisées pour améliorer l'expérience utilisateur, gérer les rendez-vous, et fournir un service personnalisé. Elles ne seront jamais vendues à des tiers.`
    ]
  },
  {
    id: '2',
    title: "3 - Protection des Données",
    content: [
      `Nous mettons en place des mesures de sécurité pour protéger vos informations contre les accès non autorisés, les altérations ou les destructions.`
    ]
  },
  {
    id: '3',
    title: "4 - Partage des Données",
    content: [
      `Vos données peuvent être partagées avec des prestataires de services qui nous aident à exploiter l'application, sous réserve de leur engagement à respecter la confidentialité de ces données.`
    ]
  },
  {
    id: '4',
    title: "5 - Conservation des Données",
    content: [
      `Les informations collectées sont conservées uniquement pendant la durée nécessaire (2 ans, à partir de la dernière utilisation) pour le bon fonctionnement du site, au bout de ces deux ans les données seront supprimées.`
    ]
  },
  {
    id: '5',
    title: "6 - Droits des Utilisateurs",
    content: [
      `Vous avez le droit d'accéder, de corriger ou de supprimer vos informations personnelles à tout moment. Pour exercer ces droits, veuillez nous contacter via l’adresse email suivante : enzo.aime91@gmail.com.`
    ]
  },
  {
    id: '6',
    title: "7 - Modifications de la Charte",
    content: [
      `Cette charte peut être mise à jour de temps en temps. Nous vous informerons de tout changement via l'application ou par email.`
    ]
  },
  {
    id: '7',
    title: "8 - Contact",
    content: [
      `Pour toute question concernant cette charte de confidentialité, veuillez nous contacter à l'adresse suivante : enzo.aime91@gmail.com.`
    ]
  }
];


const LegalPage: React.FC = () => {
  return (
    <div>
      <ChartOfConfidentialitySections sections={confidentialitySections} />
    </div>
  );
};

export default LegalPage;
