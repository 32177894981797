// cartSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Service } from '../services/types/modelService';

interface CartState {
  items: Service[];
}

const initialState: CartState = {
  items: [],
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addToCart: (state, action: PayloadAction<Service>) => {
      state.items.push(action.payload);
    },
    // Vous pouvez ajouter d'autres actions ici si nécessaire
  },
});

export const { addToCart } = cartSlice.actions;

// count items in cart
export default cartSlice.reducer;